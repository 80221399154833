import styles from "./../styles/demo.module.scss"
import formStyles from "./../styles/commons/form.module.scss"
import React, { useContext, useState } from "react"
import Layout from "../components/layout/layout"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import FormError from "../components/forms/form-error/form-error"
import CountrySelect from "../components/forms/country-select/country-select"
import { APIHelper } from "../utils/api-helper"
import { REGEX_EMAIL, REGEX_PHONE_NUMBER } from "../utils/regex"
import { FULL_NAME_MAX_LEN, FULL_NAME_MIN_LEN } from "../utils/constants"
import SEO from "../components/seo"
import withAppContext from "../contexts/app.context"
import { ShowAnnouncementContext } from "../contexts/show-announcement.context"
import * as Recaptcha from "react-recaptcha"
import { ENV_RECAPTCHA_PUBLIC_KEY } from "../utils/secrets"
import { Helper } from "../utils/helper"

const opportunitiesSchema = Yup.object().shape({
  ownerName: Yup.string()
    .trim()
    .min(FULL_NAME_MIN_LEN, "Full name is too Short!")
    .max(FULL_NAME_MAX_LEN, "Full name is too Long!")
    .required("This field is required"),
  ownerEmail: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .matches(REGEX_EMAIL, "Please enter a valid email")
    .required("This field is required"),
  ownerRvpEmail: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .matches(REGEX_EMAIL, "Please enter a valid email"),
  ownerDsm: Yup.string().trim(),
  ownerPhone: Yup.string()
    .trim()
    .matches(REGEX_PHONE_NUMBER, "Please enter a valid phone number")
    .required("This field is required"),
  accountName: Yup.string()
    .trim()
    .required("This field is required"),
  accountEmail: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .matches(REGEX_EMAIL, "Please enter a valid email")
    .required("This field is required"),
  accountID: Yup.string().trim(),
  accountCompanyName: Yup.string()
    .trim()
    .required("This field is required"),
  distributorName: Yup.string().trim(),
  f5opportunity: Yup.string().trim(),
  resellerName: Yup.string().trim(),
  resellerEmail: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .matches(REGEX_EMAIL, "Please enter a valid email"),
  enduserFirstName: Yup.string()
    .trim()
    .min(FULL_NAME_MIN_LEN, "First name is too Short!")
    .max(FULL_NAME_MAX_LEN, "First name is too Long!")
    .required("This field is required"),
  enduserLastName: Yup.string()
    .trim()
    .min(FULL_NAME_MIN_LEN, "Last name is too Short!")
    .max(FULL_NAME_MAX_LEN, "Last name is too Long!")
    .required("This field is required"),
  enduserEmail: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .matches(REGEX_EMAIL, "Please enter a valid email")
    .required("This field is required"),
  enduserJobTitle: Yup.string().trim(),
  enduserPhone: Yup.string()
    .trim()
    .matches(REGEX_PHONE_NUMBER, "Please enter a valid phone number"),
  country: Yup.string().required("This field is required"),
  useCase: Yup.string().trim(),
  region: Yup.string().trim(),
  details: Yup.string().trim(),
})

const OpportunitiesPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "opportunities" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const [requestSending, setRequestSending] = useState(false)
  const [requestSent, setRequestSent] = useState(false)

  const { showAnnouncement } = useContext(ShowAnnouncementContext)

  let recaptchaInstance
  let formValue

  const onloadCallback = () => {}

  const onFormSubmit = values => {
    formValue = values
    if (ENV_RECAPTCHA_PUBLIC_KEY) {
      recaptchaInstance.execute()
      return
    }
    postForm(values)
  }

  const verifyCallback = token => {
    const request = {
      ...formValue,
      token,
    }
    postForm(request)
  }

  const postForm = values => {
    const { ...data } = opportunitiesSchema.cast(values)
    setRequestSending(true)
    APIHelper.sendOpportunities(data)
      .then(() => {
        setRequestSending(false)
        setRequestSent(true)
        navigate("/thanks-opportunities")
      })
      .catch(() => {
        setRequestSending(false)
      })
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/f5-volterra-opportunities"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />
      <div className={styles.consultContainer}>
        <div
          className={
            `container ${styles.container}` +
            (showAnnouncement ? styles.containerIndent : "")
          }>
          <div className={`row ${styles.form__wrapper}`}>
            <Formik
              initialValues={{
                ownerName: "",
                ownerEmail: "",
                ownerRvpEmail: "",
                ownerDsm: "",
                ownerPhone: "",
                accountName: "",
                accountEmail: "",
                accountID: "",
                accountCompanyName: "",
                distributorName: "",
                f5opportunity: "",
                resellerName: "",
                resellerEmail: "",
                enduserFirstName: "",
                enduserLastName: "",
                enduserEmail: "",
                enduserJobTitle: "",
                enduserPhone: "",
                country: "",
                useCase: "",
                region: "",
                details: "",
              }}
              validationSchema={opportunitiesSchema}
              onSubmit={onFormSubmit}>
              {({ errors, touched, values }) => {
                return (
                  <Form
                    id="website-register-opportunity"
                    className={`${styles.form} col-12 col-xl-6`}
                    ves-e2e-test="demo/opportunity-form">
                    <h5>Account owner info</h5>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="ownerName">
                          Account owner name *
                        </label>
                        <Field
                          id="ownerName"
                          type="input"
                          name="ownerName"
                          className={`form-control ${formStyles.formControl}${
                            errors.ownerName && touched.ownerName
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your full name"
                        />
                        <FormError name="ownerName" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="ownerEmail">
                          Account owner email *
                        </label>
                        <Field
                          id="ownerEmail"
                          type="email"
                          name="ownerEmail"
                          className={`form-control ${formStyles.formControl}${
                            errors.ownerEmail && touched.ownerEmail
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your email"
                        />
                        <FormError name="ownerEmail" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="ownerRvpEmail">
                          Account owner RVP email
                        </label>
                        <Field
                          id="ownerRvpEmail"
                          type="email"
                          name="ownerRvpEmail"
                          className={`form-control ${formStyles.formControl}${
                            errors.ownerRvpEmail && touched.ownerRvpEmail
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your RVP email"
                        />
                        <FormError name="ownerRvpEmail" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="ownerDsm">
                          Account owner DSM
                        </label>
                        <Field
                          id="ownerDsm"
                          type="input"
                          name="ownerDsm"
                          className={`form-control ${formStyles.formControl}${
                            errors.ownerDsm && touched.ownerDsm
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your DSM"
                        />
                        <FormError name="ownerDsm" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 col-sm-6 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="ownerPhone">
                          Account owner Phone *
                        </label>
                        <Field
                          id="ownerPhone"
                          type="input"
                          name="ownerPhone"
                          className={`form-control ${formStyles.formControl}${
                            errors.ownerPhone && touched.ownerPhone
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your phone number"
                        />
                        <FormError name="ownerPhone" />
                      </div>
                    </div>

                    <h5>Account info</h5>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="accountName">
                          Account SE name *
                        </label>
                        <Field
                          id="accountName"
                          type="text"
                          name="accountName"
                          className={`form-control ${formStyles.formControl}${
                            errors.accountName && touched.accountName
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter account name"
                        />
                        <FormError name="accountName" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="accountEmail">
                          Account SE email *
                        </label>
                        <Field
                          id="accountEmail"
                          type="email"
                          name="accountEmail"
                          className={`form-control ${formStyles.formControl}${
                            errors.accountEmail && touched.accountEmail
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter account SE email"
                        />
                        <FormError name="accountEmail" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="accountID">
                          Account ID
                        </label>
                        <Field
                          id="accountID"
                          type="text"
                          name="accountID"
                          className={`form-control ${formStyles.formControl}${
                            errors.accountID && touched.accountID
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter account ID"
                        />
                        <FormError name="accountID" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="accountCompanyName">
                          Account Company Name *
                        </label>
                        <Field
                          id="accountCompanyName"
                          type="text"
                          name="accountCompanyName"
                          className={`form-control ${formStyles.formControl}${
                            errors.accountCompanyName &&
                            touched.accountCompanyName
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter account company name"
                        />
                        <FormError name="accountCompanyName" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="distributorName">
                          Distributor Name
                        </label>
                        <Field
                          id="distributorName"
                          as="select"
                          name="distributorName"
                          className={
                            "form-control" +
                            ` ${formStyles.formControl}` +
                            (errors.distributorName && touched.distributorName
                              ? " is-invalid"
                              : "")
                          }>
                          <option value="" disabled>
                            Choose distributor
                          </option>
                          <option>APAC - ACW Distribution (HK) Ltd</option>
                          <option>APAC - Agile Distribution Thailand</option>
                          <option>
                            APAC - Arrow ECS Australia Pty Limited
                          </option>
                          <option>APAC - Arrow ECS New Zealand Limited</option>
                          <option>APAC - AWAN Distribution Pte Ltd</option>
                          <option>APAC - B&Data HK</option>
                          <option>APAC - Beijing Holyzone</option>
                          <option>APAC - Bestcom Infotech Corp Taiwan</option>
                          <option>
                            APAC - Computrade Technology Philippines
                          </option>
                          <option>APAC - Digital China</option>
                          <option>APAC - Digital China Macao</option>
                          <option>
                            APAC - Exclusive Networks Asia Pte Ltd India
                          </option>
                          <option>
                            APAC - Exclusive Networks Asia Pte Ltd Philippines
                          </option>
                          <option>
                            APAC - Exclusive Networks Asia Pte Ltd Vietnam
                          </option>
                          <option>
                            APAC - Exclusive Networks Sales India Pvt Ltd
                          </option>
                          <option>APAC - Exclusive Networks S Pte Ltd</option>
                          <option>
                            APAC - Inflow Technologies (Singapore) Pte Ltd
                          </option>
                          <option>
                            APAC - Ingram Micro Pakistan (Pvt) Limited
                          </option>
                          <option>
                            APAC - Ingram Micro Trading (Shanghai)
                          </option>
                          <option>APAC - Innovix Distribution Sdn Bhd</option>
                          <option>APAC - ITian Corporation</option>
                          <option>APAC - NetFos Technology Taiwan</option>
                          <option>APAC - PT Central Data Technology</option>
                          <option>
                            APAC - PT Exclusive Networks Indonesia
                          </option>
                          <option>APAC - Redington Distribution Pte Ltd</option>
                          <option>APAC - Redington Sri Lanka</option>
                          <option>APAC - Sen Spirit Technology</option>
                          <option>
                            APAC - South Asian Technologies (Pvt) Ltd.
                          </option>
                          <option>APAC - TechData (S) Pte Ltd - India</option>
                          <option>APAC - TechData India</option>
                          <option>APAC - TechData Singapore</option>
                          <option>APAC - TechData Vietnam</option>
                          <option>APAC - Telegistics Limited</option>
                          <option>APAC - To Be Determined</option>
                          <option>APAC - Uniforce Technology Co Ltd</option>
                          <option>APAC - VST ECS (Thailand) Co.,Ltd.</option>
                          <option>APAC - VSTECS Pericomp Sdn Bhd</option>
                          <option>APAC - Westcon Australia</option>
                          <option>
                            APAC - Westcon Group (Thailand) Co Ltd
                          </option>
                          <option>APAC - Westcon Group (Vietnam) Co Ltd</option>
                          <option>APAC - Westcon Hong Kong</option>
                          <option>APAC - Westcon Indonesia</option>
                          <option>APAC - Westcon Malaysia</option>
                          <option>APAC - Westcon New Zealand</option>
                          <option>APAC - Westcon Singapore</option>
                          <option>
                            APAC - Westcon Solutions Philippines Inc
                          </option>
                          <option>APAC - Yunke Digital China Macao</option>
                          <option>APAC - Zen Exim (P) Limited</option>
                          <option>APAC - Zero One Technology Taiwan</option>
                          <option>EMEA - Abbakan France</option>
                          <option>EMEA - Advanced IP Sweden</option>
                          <option>EMEA - AgiTech Russia</option>
                          <option>EMEA - ALEF</option>
                          <option>EMEA - ALEF Distribution CZ</option>
                          <option>EMEA - ALEF Hungary</option>
                          <option>EMEA - ALEF Slovakia</option>
                          <option>EMEA - Al Jammaz Technology Ltd. Co.</option>
                          <option>EMEA - Arrow ECS Austria</option>
                          <option>EMEA - Arrow ECS Belgium</option>
                          <option>EMEA - Arrow ECS Bulgaria</option>
                          <option>EMEA - Arrow ECS Croatia</option>
                          <option>EMEA - Arrow ECS Czech Republic</option>
                          <option>EMEA - Arrow ECS Denmark</option>
                          <option>EMEA - Arrow ECS Finland</option>
                          <option>EMEA - ARROW ECS France</option>
                          <option>EMEA - ARROW ECS Germany</option>
                          <option>EMEA - Arrow ECS Israel</option>
                          <option>EMEA - ARROW ECS Italy</option>
                          <option>EMEA - Arrow ECS Lux</option>
                          <option>EMEA - Arrow ECS Middle East</option>
                          <option>EMEA - Arrow ECS Netherlands</option>
                          <option>EMEA - Arrow ECS Norway</option>
                          <option>EMEA - Arrow ECS Portugal</option>
                          <option>EMEA - Arrow ECS Serbia</option>
                          <option>EMEA - Arrow ECS Slovenia</option>
                          <option>EMEA - Arrow ECS Spain</option>
                          <option>EMEA - Arrow ECS Sweden</option>
                          <option>EMEA - Arrow ECS Switzerland</option>
                          <option>EMEA - Arrow ECS UK</option>
                          <option>EMEA - Avnet UK</option>
                          <option>EMEA - Axiz</option>
                          <option>EMEA - Bakotech Armenia</option>
                          <option>EMEA - Bakotech Azerbaijan</option>
                          <option>EMEA - Bakotech Belarus</option>
                          <option>EMEA - Bakotech Georgia</option>
                          <option>EMEA - Bakotech Moldova</option>
                          <option>EMEA - Bakotech Ukraine</option>
                          <option>EMEA - Clico Bulgaria</option>
                          <option>EMEA - Clico Poland</option>
                          <option>EMEA - Computer C-Data Israel</option>
                          <option>EMEA - Computerlinks Finland</option>
                          <option>EMEA - Computerlinks France</option>
                          <option>EMEA - Computerlinks Germany</option>
                          <option>EMEA - Computerlinks Hungary</option>
                          <option>EMEA - Computerlinks Italy</option>
                          <option>EMEA - Computerlinks Middle East</option>
                          <option>EMEA - Computerlinks Netherlands</option>
                          <option>EMEA - Computerlinks Norway</option>
                          <option>EMEA - Computerlinks Sweden</option>
                          <option>EMEA - Computerlinks Switzerland</option>
                          <option>EMEA - Computerlinks UK</option>
                          <option>EMEA - CONFIG</option>
                          <option>EMEA - Distrilogie Belgium</option>
                          <option>
                            EMEA - DNS Network Technologies Denmark
                          </option>
                          <option>EMEA - Exclusive Networks BeLux</option>
                          <option>EMEA - Exclusive Networks Germany</option>
                          <option>
                            EMEA - Exclusive Networks North Africa
                          </option>
                          <option>EMEA - Exclusive Networks Secureway</option>
                          <option>
                            EMEA - Exclusive Networks Secureway KSA
                          </option>
                          <option>EMEA - Exclusive Networks Turkey</option>
                          <option>EMEA - Exclusive Networks UK</option>
                          <option>EMEA - Exclusive Networks West Africa</option>
                          <option>EMEA - Exertis Ireland</option>
                          <option>EMEA - Head Technology</option>
                          <option>EMEA - ICOS Italy</option>
                          <option>EMEA - inTellect Turkey</option>
                          <option>EMEA - Itway Italy</option>
                          <option>EMEA - Itway Portugal</option>
                          <option>EMEA - Magirus Iberia</option>
                          <option>EMEA - Magirus Italy</option>
                          <option>EMEA - Magirus Spain</option>
                          <option>EMEA - MCS Holding</option>
                          <option>EMEA - Neteks Turkey</option>
                          <option>EMEA - Networks Unlimited Africa</option>
                          <option>
                            EMEA - Networks Unlimited South Africa
                          </option>
                          <option>EMEA - Prolink Turkey</option>
                          <option>EMEA - Provision Romania</option>
                          <option>
                            EMEA - Safad Engineering and Electronics Co. Ltd
                          </option>
                          <option>EMEA - TIM AG</option>
                          <option>EMEA - Veracomp Adriatics</option>
                          <option>EMEA - Veracomp Hungary</option>
                          <option>EMEA - Veracomp Poland</option>
                          <option>EMEA - Veracomp Romania</option>
                          <option>EMEA - Westcon Austria</option>
                          <option>EMEA - Westcon Belux</option>
                          <option>EMEA - Westcon Bulgaria</option>
                          <option>EMEA - Westcon Comztek (Pty) Ltd</option>
                          <option>EMEA - Westcon Cyprus</option>
                          <option>EMEA - Westcon Czech Republic</option>
                          <option>EMEA - Westcon Denmark</option>
                          <option>EMEA - Westcon Egypt</option>
                          <option>EMEA - Westcon Estonia</option>
                          <option>EMEA - Westcon Finland</option>
                          <option>EMEA - Westcon France</option>
                          <option>EMEA - Westcon Germany</option>
                          <option>EMEA - Westcon Greece</option>
                          <option>EMEA - Westcon Hungary</option>
                          <option>EMEA - Westcon Italy</option>
                          <option>EMEA - Westcon KSA</option>
                          <option>EMEA - Westcon Latvia</option>
                          <option>EMEA - Westcon Lithuania</option>
                          <option>EMEA - Westcon Middle East</option>
                          <option>EMEA - Westcon Netherlands</option>
                          <option>EMEA - Westcon North Africa</option>
                          <option>EMEA - Westcon Norway</option>
                          <option>EMEA - Westcon Poland</option>
                          <option>EMEA - Westcon Portugal</option>
                          <option>EMEA - Westcon Romania</option>
                          <option>EMEA - Westcon Slovakia</option>
                          <option>EMEA - Westcon Slovenia</option>
                          <option>EMEA - Westcon South Africa</option>
                          <option>EMEA - Westcon Spain</option>
                          <option>EMEA - Westcon Sub-Sahara Africa</option>
                          <option>EMEA - Westcon Sweden</option>
                          <option>EMEA - Westcon Switzerland</option>
                          <option>EMEA - Westcon Turkey</option>
                          <option>EMEA - Westcon UK</option>
                          <option>EMEA - Xenya Slovenia</option>
                          <option>EMEA - Zycko France</option>
                          <option>JAPAN - CTC</option>
                          <option>JAPAN - Macnica Networks</option>
                          <option>JAPAN - Macnica Solutions</option>
                          <option>JAPAN - NEC(NCOS)</option>
                          <option>JAPAN - Net One Systems</option>
                          <option>JAPAN - Networld</option>
                          <option>JAPAN - NTT-AT</option>
                          <option>JAPAN - Techmatrix</option>
                          <option>JAPAN - TED</option>
                          <option>LATAM - Adistec Colombia</option>
                          <option>LATAM - Adistec Corp</option>
                          <option>LATAM - Adistec Costa Rica</option>
                          <option>LATAM - Adistec Panama</option>
                          <option>LATAM - Adistec Peru</option>
                          <option>LATAM - Grupo Dice Mexico</option>
                          <option>LATAM - Ingram Argentina</option>
                          <option>LATAM - Ingram Micro Brazil</option>
                          <option>LATAM - Ingram Micro Mexico</option>
                          <option>LATAM - Ingram Uruguay</option>
                          <option>LATAM - ISECOM S.A.</option>
                          <option>
                            LATAM - Licencias on Line Lolcom, S A Colombia
                          </option>
                          <option>
                            LATAM - Licencias on Line Lolcom, S A Ecuador
                          </option>
                          <option>LATAM - Network1</option>
                          <option>LATAM - Network1 Brazil</option>
                          <option>LATAM - Network1 Mexico</option>
                          <option>LATAM - Nexsys de Bolivia S.A.</option>
                          <option>LATAM - Nexsys de Chile S.A.</option>
                          <option>LATAM - Nexsys Del Peru S.A.S.</option>
                          <option>LATAM - Tech Data</option>
                          <option>LATAM - Westcon</option>
                          <option>LATAM - Westcon Argentina</option>
                          <option>LATAM - Westcon Brazil</option>
                          <option>LATAM - Westcon CCA</option>
                          <option>LATAM - Westcon Chile</option>
                          <option>LATAM - Westcon Colombia/Ecuador</option>
                          <option>LATAM - Westcon Mexico</option>
                          <option>LATAM - Westcon Peru</option>
                          <option>LATAM - Westcon Venezuela</option>
                          <option>NA - Carahsoft</option>
                          <option>NA - Ingram Micro</option>
                          <option>NA - SP Telcobuy</option>
                          <option>NA - Synnex</option>
                          <option>NA - Tech Data</option>
                          <option>None</option>
                        </Field>
                        <FormError name="distributorName" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="f5opportunity">
                          Is this an F5 Partner-initiated Opportunity?
                        </label>
                        <Field
                          id="f5opportunity"
                          as="select"
                          name="f5opportunity"
                          className={
                            "form-control" +
                            ` ${formStyles.formControl}` +
                            (errors.f5opportunity && touched.f5opportunity
                              ? " is-invalid"
                              : "")
                          }>
                          <option value="" disabled>
                            Please select type
                          </option>
                          <option>FIO</option>
                          <option>PIO</option>
                        </Field>
                        <FormError name="f5opportunity" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="resellerName">
                          Reseller Name (If Known)
                        </label>
                        <Field
                          id="resellerName"
                          type="text"
                          name="resellerName"
                          className={`form-control ${formStyles.formControl}${
                            errors.resellerName && touched.resellerName
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter reseller name"
                        />
                        <FormError name="resellerName" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="resellerEmail">
                          Reseller Email (If Known)
                        </label>
                        <Field
                          id="resellerEmail"
                          type="email"
                          name="resellerEmail"
                          className={`form-control ${formStyles.formControl}${
                            errors.resellerEmail && touched.resellerEmail
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter reseller email"
                        />
                        <FormError name="resellerEmail" />
                      </div>
                    </div>

                    <h5>End user info</h5>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 col-sm-6 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="enduserFirstName">
                          End User First Name *
                        </label>
                        <Field
                          id="enduserFirstName"
                          type="text"
                          name="enduserFirstName"
                          className={`form-control ${formStyles.formControl}${
                            errors.enduserFirstName && touched.enduserFirstName
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter end user first name"
                        />
                        <FormError name="enduserFirstName" />
                      </div>
                      <div className="col-12 col-sm-6 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="enduserLastName">
                          End User Last Name *
                        </label>
                        <Field
                          id="enduserLastName"
                          type="text"
                          name="enduserLastName"
                          className={`form-control ${formStyles.formControl}${
                            errors.enduserLastName && touched.enduserLastName
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter end user last name"
                        />
                        <FormError name="enduserLastName" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="enduserEmail">
                          End User Contact Email *
                        </label>
                        <Field
                          id="enduserEmail"
                          type="email"
                          name="enduserEmail"
                          className={`form-control ${formStyles.formControl}${
                            errors.enduserEmail && touched.enduserEmail
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter email"
                        />
                        <FormError name="enduserEmail" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="enduserJobTitle">
                          End User Job Title
                        </label>
                        <Field
                          id="enduserJobTitle"
                          type="text"
                          name="enduserJobTitle"
                          className={`form-control ${formStyles.formControl}${
                            errors.enduserJobTitle && touched.enduserJobTitle
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter job title"
                        />
                        <FormError name="enduserJobTitle" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 col-sm-6 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="enduserPhone">
                          End User Phone
                        </label>
                        <Field
                          id="enduserPhone"
                          type="input"
                          name="enduserPhone"
                          className={`form-control ${formStyles.formControl}${
                            errors.enduserPhone && touched.enduserPhone
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter phone number"
                        />
                        <FormError name="enduserPhone" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="country">
                          Country *
                        </label>
                        <CountrySelect
                          id="country"
                          name="country"
                          className={
                            "form-control" +
                            ` ${formStyles.formControl}` +
                            (errors.country && touched.country
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <FormError name="country" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="useCase">
                          Use Case
                        </label>
                        <Field
                          id="useCase"
                          as="select"
                          name="useCase"
                          className={
                            "form-control" +
                            ` ${formStyles.formControl}` +
                            (errors.useCase && touched.useCase
                              ? " is-invalid"
                              : "")
                          }>
                          <option value="" disabled>
                            Please select use case
                          </option>
                          <option>Multi-cloud Networking</option>
                          <option>Secure Kubernetes gateway</option>
                          <option>DDoS mitigation</option>
                          <option>Web App Security & Performance</option>
                          <option>App Delivery Network (ADN)</option>
                          <option>Infrastructure and App management</option>
                          <option>Edge Networking & Security</option>
                        </Field>
                        <FormError name="useCase" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="region">
                          Region
                        </label>
                        <Field
                          id="region"
                          as="select"
                          name="region"
                          className={
                            "form-control" +
                            ` ${formStyles.formControl}` +
                            (errors.region && touched.region
                              ? " is-invalid"
                              : "")
                          }>
                          <option value="" disabled>
                            Please select region
                          </option>
                          <option>NA - North American Major Accounts</option>
                          <option>NA - NGINX</option>
                          <option>EMEA - UK & Ireland</option>
                          <option>EMEA – France</option>
                          <option>EMEA - NGINX</option>
                          <option>APCJ – Japan</option>
                          <option>APCJ - ASEAN</option>
                          <option>APCJ - NGINX</option>
                          <option>Service Provider</option>
                          <option>Approved Exception</option>
                        </Field>
                        <FormError name="region" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="details">
                          Opportunity details
                        </label>
                        <Field
                          id="details"
                          component="textarea"
                          name="details"
                          className={`form-control ${formStyles.formArea} ${
                            formStyles.formControl
                          }${
                            errors.details && touched.details
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Pain, authority, need..."
                        />
                        <FormError name="details" />
                      </div>
                    </div>

                    <div className={`${formStyles.formGroup} form-row`}>
                      <button
                        ves-e2e-test="opportinities/form-submit-btn"
                        aria-label="Send"
                        type="submit"
                        disabled={requestSending || requestSent}
                        className={`col btn btn-primary ${styles.submit__button}`}>
                        {requestSending || requestSent
                          ? "Sending..."
                          : "Submit opportunity details"}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
            <div className="col-12 col-xl-6 d-flex justify-content-center">
              <div className={styles.example}>
                <div>
                  <h2 className={styles.description__title}>
                    Register a Volterra Qualified Opportunity
                  </h2>
                  <p className={styles.example__text}>
                    Use this form to register a Volterra opportunity. After
                    filling out this form, someone from Volterra will be in
                    touch for next steps in the sales process.
                  </p>

                  <h3 className={styles.description__subtitle}>
                    Required Fields
                  </h3>
                  <p>
                    Please fill out as many fields as possible. For{" "}
                    <strong>F5 Account ID</strong>, please{" "}
                    <a
                      href="https://f5.lightning.force.com/lightning/r/Report/00O1T000006UR7M/view"
                      target="_blank">
                      refer to this F5 report
                    </a>
                    .
                  </p>

                  <h3 className={styles.description__subtitle}>
                    Qualification Criteria
                  </h3>
                  <p>
                    Please be sure business drivers and customer pain are
                    identified before submitting the opportunity.
                  </p>
                  <ul className={styles.list}>
                    <li>
                      <strong>
                        Timeframe: 6-12 months (or less) expected close date.
                      </strong>
                    </li>
                    <li>
                      <strong>Understanding of decision maker(s).</strong>
                    </li>
                    <li>
                      <strong>
                        Understanding of buying process and use case.
                      </strong>
                    </li>
                    <li>
                      <strong>Budget discussion initiated.</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Helper.isBrowser() && ENV_RECAPTCHA_PUBLIC_KEY && (
        <Recaptcha
          ref={e => (recaptchaInstance = e)}
          sitekey={ENV_RECAPTCHA_PUBLIC_KEY}
          onloadCallback={onloadCallback}
          render="explicit"
          size="invisible"
          verifyCallback={verifyCallback}
        />
      )}
    </Layout>
  )
}

export default withAppContext(OpportunitiesPage)
